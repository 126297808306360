import React from 'react';
  
const Blogs = () => {
  return (
    <div>
        <h1>Music for the Future!</h1>
        <p>FloodBeats. is headed to NAMM next Spirng.</p>
    </div>
  );
};
  
export default Blogs;