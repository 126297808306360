import React from 'react';
  
const Contact = () => {
  return (
    <div>
      <h2>Mail us for feedback jamesfloodjr0@gmail.com</h2>
    </div>
  );
};
  
export default Contact;