import React from "react";

const About = () => {
    return (
        <div>
            <h1></h1>
            <p>FloodBeats. is a web digital audio workstation.<br></br> It's Features will grow to include recording, saving, and exporting.</p>
        </div>
    )
}

export default About